.private-layout {
    .footer-container {
        display: flex;
        justify-content: space-between;
        padding: 45px 60px;
        background: #533895;

        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        text-align: center;
        letter-spacing: -0.0426667px;

        span {
            color: rgba(255, 255, 255, 0.4);
        }

        .nav-block {
            .nav-item {
                color: #fff;
            }
        }
    }
}

@media screen and (max-width: 1025px) {
    .private-layout {
        .footer-container {
            justify-content: center;

            .nav-block {
                display: none;
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .private-layout {
        .sider-mobile {
            display: block;
        }

        .header {
            padding: 0 20px;
            box-shadow: 4px 5px 5px rgba(0, 0, 0, 0.05);
        }

        .footer-container {
            padding: 35px 40px;
        }
    }
}