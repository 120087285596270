.public-page-container {
    min-height: 100vh;

    .main-container{
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 80px 20px;
    }

    .footer-container {
        justify-content: center;
        padding-bottom: 70px;

        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        text-align: center;
        letter-spacing: -0.0426667px;
        color: #D9D9D9;
    }
}

@media screen and (max-width: 992px) {
    .public-page-container {
        .footer-container {
            padding-right: 50px;
        }
    }
}

@media screen and (max-width: 575px) {
}